<template>
  <div class="container mx-auto row justify-content-center my-5 pb-3">
    <div class="col-8">
      <form
        class="d-flex flex-column align-items-center"
        @submit.prevent="saveMails()">
        <input
          type="file"
          class="upload-excel d-none"
          @change="onFileChange"
          id="uploadTxt" />
        <label
          for="uploadTxt"
          class="form-file-label"
          :class="{ active: txt_file }"
          id="formFileLabel"></label>
        <transition name="blur" mode="out-in">
          <div class="buttons d-flex mb-3" v-if="txt_file">
            <button
              type="reset"
              @click="clearFile"
              v-if="!loading"
              class="btn btn-sm btn-danger btn-iconed shadow me-2">
              Vazgeç
              <i class="bi bi-x-lg ms-2"></i>
            </button>
            <button
              type="submit"
              v-if="!loading"
              class="btn btn-sm btn-secondary btn-iconed shadow ms-2">
              Yükle
              <i class="bi bi-cloud-upload ms-2"></i>
            </button>
            <button
              type="submit"
              v-else
              disabled
              class="btn btn-sm btn-secondary btn-iconed shadow ms-2">
              Yükleniyor...
              <i class="bi bi-cloud-upload ms-2"></i>
            </button>
          </div>
        </transition>
      </form>
      <div class="alert alert-warning d-flex align-items-center mb-0">
        <i class="bi bi-exclamation-triangle me-3"></i>
        <span class="fw-500 text-small">
          Lütfen txt dosyası yüklemeden önce aşağıdaki kurallara göz atın,
          kurallara uygun olmayan dosyalar kayıt edilmeyecektir.
        </span>
      </div>
      <strong class="d-block my-3 fw-500 fs-5 text-dark">
        TXT yükleme kuralları
      </strong>
      <ul>
        <li>Dosya uzantısı sadece veya <strong>.txt</strong> olmalıdır.</li>
        <li>Dosya boyutu en fazla <strong>100MB</strong> olabilir.</li>
        <li>Dosya veri düzeni sırasıyla şu şekilde olmalıdır;</li>
        <ul>
          <li>
            ornek@mail.com <br />
            ornek2@mail.com <br />
            ornek3@mail.com <br />
            ...
          </li>
        </ul>
        <li>
          TXT dosyanız boyutuna bağlı olarak yüklemesi biraz zaman alabilir,
          lütfen bekleyin. İşlem tamamlandığında olumlu veya olumsuz bir dönüş
          alırsınız.
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SaveMails",
  data() {
    return {
      content: "",
      buttons: false,
      txt_file: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      _active_mails_page: "Mails/_active_mails_page",
    }),
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createFile(files[0]);
    },
    createFile(file) {
      document
        .querySelector("#formFileLabel")
        .setAttribute("data-file", file.name);
      this.txt_file = file;
    },
    clearFile() {
      this.txt_file = "";
      document.querySelector("#formFileLabel").removeAttribute("data-file");
      document.querySelector("#uploadTxt").value = null;
    },
    saveMails() {
      this.loading = true;
      let formData = new FormData();
      formData.append("txt_file", this.txt_file);

      this.$store
        .dispatch("Mails/SAVE_MAILS", formData)
        .then((res) => {
          this.clearFile();
          this.$swal({
            icon: "success",
            text: res?.message,
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            text: err?.message,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
.form-file-label {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  font-size: 1.35rem;
  border-radius: 0.5rem;
  font-weight: 600;
  width: 100%;
  margin-bottom: 1.5rem;
  color: rgba(var(--bs-dark-rgb), 0.8);
  border: 2px dashed rgb(var(--bs-secondary-rgb));
  background: rgb(var(--bs-white-rgb));
  transition: all 300ms ease-in-out;
  &::before {
    content: "Mailleri yüklemek için bir txt dosyası seçiniz";
  }

  &.active {
    color: rgba(var(--bs-success-rgb), 0.8);
    border: 2px dashed rgb(var(--bs-success-rgb));
    background: rgba(var(--bs-success-rgb), 0.1);
    &::before {
      content: "Dosya seçiminiz: " attr(data-file);
    }
  }
}
</style>
