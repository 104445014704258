<template>
  <div class="sidebar">
    <div>
      <img src="@/assets/logo.svg" alt="" />
    </div>

    <ul>
      <li>
        <router-link :to="{ name: 'Home' }" active-class="active">
          <i class="bi bi-inboxes"></i> Mail Listesi
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'MailSend' }" active-class="active">
          <i class="bi bi-envelope-arrow-up"></i> Mail Gönderimi
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Views' }" active-class="active">
          <i class="bi bi-eye"></i> Görüntülenmeler
        </router-link>
      </li>
    </ul>

    <small class="text-secondary mt-auto text-center">
      keyiflerolsun.dev © v{{ version }}
    </small>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import packageInfo from "../../../package";

export default {
  name: "AppSidebar",
  computed: {
    ...mapGetters({
      _admin: "Auth/_admin",
    }),
  },
  data() {
    return {
      version: packageInfo.version,
    };
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  height: 100vh;
  width: 275px;
  min-width: 275px;
  border-right: 1px solid #e3e3e3;
  padding: 20px;
  margin-right: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  ul {
    padding-left: 0;
    list-style: none;
    text-align: start;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    li {
      display: flex;
      a {
        width: 100%;
        padding: 15px;
        border-radius: 1rem;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        color: black;

        i {
          margin-right: 8px;
        }

        &:hover {
          background-color: #fdf4f2;
          color: #ff3c10;
        }

        &.active {
          background-color: #ff3c10;
          color: #fff;
        }
      }
    }
  }
}
</style>
