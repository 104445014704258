<template>
  <main id="MailsView">
    <mail-software-title title="Mail Listesi" />

    <MailsList />
    <SaveMails />
  </main>
</template>

<script>
import MailsList from "@/components/Mails/MailsList";
import SaveMails from "@/components/Mails/SaveMails";
import { mapGetters } from "vuex";

export default {
  name: "MailsView",
  components: {
    MailsList,
    SaveMails,
  },
  computed: {
    ...mapGetters({
      _admin: "Auth/_admin",
      _active_mails_page: "Mails/_active_mails_page",
    }),
  },
  mounted() {
    this.$store
      .dispatch("Mails/GET_MAILS", this._active_mails_page)
      .then(() => {
        this.$store.dispatch("Mails/GET_MAILS_PAGES").catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        });
      })
      .catch((err) => {
        this.$swal({
          icon: "warning",
          text: err?.message,
        });
      });
  },
};
</script>

<style lang="scss" scoped>
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;

  img {
    width: 500px;
  }
}
</style>
