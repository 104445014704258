<template>
  <div class="modal fade" id="mailLogsModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Okunan maillerin konu başlıkları</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="list-group">
            <li class="list-group-item" v-for="log of _logs" :key="log">
              {{ log.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import { mapGetters } from "vuex";

export default {
  name: "MailLogsPopup",
  computed: {
    ...mapGetters({
      _logs: "Logs/_logs",
    }),
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit(
      "Popups/setMailLogsPopup",
      new bootstrap.Modal("#mailLogsModal")
    );
  },
  methods: {},
};
</script>
<style lang=""></style>
