import axios from "axios";

export default {
  namespaced: true,
  state: {
    logs: [],
    all_logs: [],
  },
  getters: {
    _logs(state) {
      return state.logs;
    },
    _all_logs(state) {
      return state.all_logs;
    },
  },
  mutations: {
    setLogs(state, payload) {
      state.logs = payload;
    },
    setAllLogs(state, payload) {
      state.all_logs = payload;
    },
  },
  actions: {
    GET_LOG(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/logs/get`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
            params: {
              mail: payload,
            },
          })
          .then((res) => {
            context.commit("setLogs", res.data.logs);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setLogs", []);
            reject(err.response.data);
          });
      });
    },
    GET_LOGS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/logs/all`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            context.commit("setAllLogs", res.data.logs);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setAllLogs", []);
            reject(err.response.data);
          });
      });
    },
  },
};
