<template>
  <div class="admins mt-4">
    <div class="header">
      <div class="buttons">
        <button
          class="btn btn-danger shadow"
          :disabled="delete_loading"
          @click="DeleteAll">
          <i class="bi bi-trash"></i>
        </button>
        <button
          class="btn btn-info shadow"
          :disabled="sync_loading"
          @click="SyncData">
          <i class="bi bi-cloud-arrow-down" v-if="!sync_loading"></i>
          <i class="bi bi-cloud-download" v-else></i>
        </button>
      </div>
    </div>
    <table class="table table-striped mt-3" v-if="_mails.length > 0">
      <thead>
        <tr>
          <th class="text-center" width="50">Sıra</th>
          <th width="150">E-Posta</th>
          <th class="text-center" width="150">İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="[index, mail] of _mails.entries()" :key="mail">
          <th class="text-center">
            #{{ (current_page - 1) * 10 + (index + 1) }}
          </th>
          <td>
            {{ mail.mail }}
          </td>
          <td class="text-center">
            <button
              @click="OpenDetail(mail)"
              class="btn btn-sm btn-warning shadow">
              <i class="bi bi-eye"></i>
            </button>
            <button
              @click="DeleteMail(mail._id)"
              class="btn btn-sm btn-danger shadow">
              <i class="bi bi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="alert alert-warning mt-3 mb-0">
      <i class="bi bi-exclamation-triangle me-3"></i>
      Henüz mail bulunamadı. Aşağı alandan sadece txt yükleyerek mail
      ekleyebilirsiniz.
    </div>

    <div
      className="d-flex justify-content-center align-items-center gap-2 mb-5"
      v-if="_mail_pages > 1">
      <p className="mb-0">{{ current_page }}/{{ _mail_pages }}</p>
      <button
        className="btn btn-info"
        :disabled="current_page == 1"
        @click="SetPage(1)">
        <i className="bi bi-chevron-double-left"></i>
      </button>
      <button
        className="btn btn-info"
        :disabled="current_page - 1 < 1"
        @click="SetPage(current_page - 1)">
        <i className="bi bi-chevron-left"></i>
      </button>
      <button
        :key="v"
        className="btn btn-primary"
        :disabled="current_page == v"
        @click="SetPage(v)"
        v-for="v in rangePage">
        {{ v }}
      </button>
      <button
        className="btn btn-info"
        :disabled="current_page + 1 > _mail_pages"
        @click="SetPage(current_page + 1)">
        <i className="bi bi-chevron-right"></i>
      </button>
      <button
        className="btn btn-info"
        @click="SetPage(_mail_pages)"
        :disabled="current_page == _mail_pages">
        <i className="bi bi-chevron-double-right"></i>
      </button>
    </div>

    <MailLogsPopup />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MailLogsPopup from "@/components/popups/MailLogs";

export default {
  name: "MailsList",
  components: {
    MailLogsPopup,
  },
  computed: {
    ...mapGetters({
      _mails: "Mails/_mails",
      _mail_pages: "Mails/_mail_pages",
      _logs: "Logs/_logs",
    }),
    rangePage() {
      return this._mail_pages > 5
        ? this.current_page + 2 > this._mail_pages
          ? [
              this._mail_pages - 4,
              this._mail_pages - 3,
              this._mail_pages - 2,
              this._mail_pages - 1,
              this._mail_pages,
            ]
          : this.current_page - 2 < 1
          ? [1, 2, 3, 4, 5]
          : [
              this.current_page - 2,
              this.current_page - 1,
              this.current_page,
              this.current_page + 1,
              this.current_page + 2,
            ]
        : [...Array(this._mail_pages).keys()].map((i) => i + 1);
    },
  },
  data() {
    return {
      sync_loading: false,
      delete_loading: false,
      current_page: 1,
    };
  },
  methods: {
    SyncData() {
      this.sync_loading = true;
      this.$store
        .dispatch("Mails/GET_MAILS", this.current_page)
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
    },
    SetPage(page) {
      this.current_page = page;
      this.$store
        .dispatch("Mails/GET_MAILS", page)
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
    },
    DeleteAll() {
      this.delete_loading = true;
      this.$store
        .dispatch("Mails/DELETE_ALL_MAILS")
        .then(() => {
          this.sync_loading = true;
          this.$store
            .dispatch("Mails/GET_MAILS", this.current_page)
            .then(() => {
              this.$store.dispatch("Mails/GET_MAILS_PAGES").catch((err) => {
                this.$swal({
                  icon: "warning",
                  text: err?.message,
                });
              });
            })
            .catch(() => {})
            .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.delete_loading = false), 1000));
    },
    DeleteMail(id) {
      this.sync_loading = true;
      this.$store
        .dispatch("Mails/DELETE_MAIL", id)
        .then(() => {
          this.$store
            .dispatch("Mails/GET_MAILS", this.current_page)
            .then(() => {
              this.$store.dispatch("Mails/GET_MAILS_PAGES").catch((err) => {
                this.$swal({
                  icon: "warning",
                  text: err?.message,
                });
              });
            })
            .catch((err) => {
              this.$swal({
                icon: "warning",
                text: err?.message,
              });
            });
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        })
        .finally(() => setTimeout(() => (this.sync_loading = false), 1000));
    },
    OpenDetail(mail) {
      this.$store
        .dispatch("Logs/GET_LOG", mail.mail)
        .then(() => {
          this.$store.getters["Popups/_mail_logs_popup"].show();
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err?.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.admins {
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgb(211, 211, 211);
  border-radius: 0.75rem;
  padding: 15px;
  background-color: white;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .buttons {
      button:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }

  table {
    th {
      vertical-align: middle;
      color: #272727;
      font-weight: 600;
    }

    td {
      vertical-align: middle;
    }
  }

  .d-flex {
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h6 {
      display: flex;
      flex-direction: column;

      small {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}
</style>
