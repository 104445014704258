import { createStore } from "vuex";

import Auth from "./modules/Auth";
import Admins from "./modules/Admins";
import Mails from "./modules/Mails";
import Tasks from "./modules/Tasks";
import Popups from "./modules/Popups";
import Logs from "./modules/Logs";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Admins,
    Mails,
    Tasks,
    Popups,
    Logs,
  },
});
