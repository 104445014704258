import axios from "axios";

export default {
  namespaced: true,
  state: {
    mails: [],
    active_mails_page: 1,
    mail_pages: 0,
    total_mails: 0,
  },
  getters: {
    _mails(state) {
      return state.mails;
    },
    _active_mails_page(state) {
      return state.active_mails_page;
    },
    _mail_pages(state) {
      return state.mail_pages;
    },
    _total_mails(state) {
      return state.total_mails;
    },
  },
  mutations: {
    setMails(state, payload) {
      state.mails = payload;
    },
    setActiveMailsPage(state, payload) {
      state.active_mails_page = payload;
    },
    setMailPages(state, payload) {
      state.mail_pages = payload;
    },
    setTotalMails(state, payload) {
      state.total_mails = payload;
    },
  },
  actions: {
    GET_MAILS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mails`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
            params: {
              page: payload,
            },
          })
          .then((res) => {
            context.commit("setMails", res.data.mails);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setMails", []);
            reject(err.response.data);
          });
      });
    },
    GET_MAILS_PAGES(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mails/pages`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            context.commit("setMailPages", res.data.page_count);
            context.commit("setTotalMails", res.data.total_count);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setMailPages", 0);
            context.commit("setTotalMails", 0);
            reject(err.response.data);
          });
      });
    },
    SAVE_MAILS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mails/save`, payload, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    DELETE_MAIL(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mails/delete/${payload}`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    DELETE_ALL_MAILS(context) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mails/delete_all`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    SEND_MAILS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mails/send_mail`, payload, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
  },
};
