export default {
  namespaced: true,
  state: {
    mail_logs_popup: [],
  },
  getters: {
    _mail_logs_popup(state) {
      return state.mail_logs_popup;
    },
  },
  mutations: {
    setMailLogsPopup(state, payload) {
      state.mail_logs_popup = payload;
    },
  },
  actions: {},
};
